<template>
  <a-card
    hoverable
    class="w-100"
    :body-style="{
      padding: isLoading ? '0.45em 2em' : '24px',
      display: 'flex'
    }"
  >
    <template #title>
      <a-typography-title :level="5" class="card-title">
        {{ card.label }}
      </a-typography-title>
    </template>

    <template #extra>
      <a-tooltip :title="card.description">
        <QuestionOutlined class="info-icon" />
      </a-tooltip>
    </template>

    <a-skeleton
      :loading="isLoading"
      :paragraph="{ rows: 1, width: ['100%', '50%'] }"
    >
      <a-typography-title :level="3" class="card-value">
        {{ card.value }}
      </a-typography-title>
      <a-statistic
        v-if="Object.hasOwn(card, 'goodCycles')"
        class="ml-auto mr-4"
        title="Good Cycles"
        :value="card.goodCycles"
        :value-style="{
          fontSize: '16px',
          fontWeight: 'bold'
        }"
      />
      <a-statistic
        v-if="Object.hasOwn(card, 'badCycles')"
        title="Bad Cycles"
        :value="card.badCycles"
        :value-style="{
          fontSize: '16px',
          fontWeight: 'bold'
        }"
      />
    </a-skeleton>
  </a-card>
</template>

<script>
import { mapGetters } from 'vuex';
import { QuestionOutlined } from '@ant-design/icons-vue';
import {
  cardIndicesForFirstDateRangeWithComparison,
  cardIndicesForSecondDateRange
} from 'src/config/charts-config.js';

export default {
  components: {
    QuestionOutlined
  },
  props: {
    card: {
      type: Object,
      required: true,
      default: () => ({})
    },
    cardIndex: {
      type: Number,
      default: -1,
      required: true
    }
  },
  computed: {
    ...mapGetters([
      'isFetchingCycles',
      'showCompareAnalytics',
      'isFetchingAnalyticsForComparison'
    ]),
    isLoading() {
      const {
        showCompareAnalytics,
        isFetchingCycles,
        cardIndex,
        isFetchingAnalyticsForComparison
      } = this;
      if (showCompareAnalytics) {
        if (cardIndicesForFirstDateRangeWithComparison.includes(cardIndex))
          return isFetchingCycles;
        else if (cardIndicesForSecondDateRange.includes(cardIndex))
          return isFetchingAnalyticsForComparison;
      } else {
        return isFetchingCycles;
      }
      return false;
    }
  }
};
</script>
<style scoped>
.card-title {
  color: #213547;
}
.card-value {
  letter-spacing: 1px;
  line-height: 1.2;
}

.info-icon {
  cursor: pointer;
  color: rgba(0, 0, 0, 0.4);
  font-size: 0.8em !important;
}
</style>
