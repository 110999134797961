<template>
  <a-row :gutter="[30, 0]">
    <a-col :span="isAnalytics ? 12 : 24">
      <a-row :gutter="[8, 16]">
        <!-- Task Selector -->
        <a-col :span="isAnalytics ? 12 : 6">
          <a-select
            id="analytics-trace-select-task-filter"
            ref="analytics_trace_task_select"
            class="w-100"
            :value="selectedTask"
            show-search
            placeholder="Select Operation"
            :options="taskOptions"
            :loading="isFetchingTasks"
            :filter-option="filterOption"
            :getPopupContainer="getPopupContainer"
            @change="handleTaskSelect"
          />
        </a-col>

        <!-- Date Range Picker -->
        <a-col :span="isAnalytics ? 6 : 3">
          <a-date-picker
            class="w-100"
            id="analytics-trace-date-range-filter"
            show-time
            :placeholder="'Start date (yyyy-mm-dd)'"
            :getPopupContainer="getPopupContainer"
            v-model:value="dateRange[0]"
            :disabled-date="disabledStartDate"
          />
        </a-col>
        <a-col :span="isAnalytics ? 6 : 3">
          <a-date-picker
            class="w-100"
            id="analytics-trace-date-range-filter"
            show-time
            :placeholder="'End date (yyyy-mm-dd)'"
            :getPopupContainer="getPopupContainer"
            v-model:value="dateRange[1]"
            :disabled-date="disabledEndDate"
          />
          <!-- <a-range-picker
            class="w-100"
            id="analytics-trace-date-range-filter"
            v-model:value="dateRange"
            show-time
            :placeholder="['Start date (yyyy-mm-dd)', 'End date (yyyy-mm-dd)']"
            :getPopupContainer="getPopupContainer"
          /> -->
        </a-col>

        <!-- Devices Selector -->
        <a-col :span="isAnalytics ? 12 : 6">
          <a-select
            id="analytics-trace-select-device-filter"
            v-model:value="selectedDevices"
            mode="multiple"
            class="w-100"
            placeholder="Select Device(s)"
            :options="deviceOptions"
            :loading="isFetchingDevices"
            :filter-option="filterOption"
            :getPopupContainer="getPopupContainer"
            :max-tag-count="1"
          />
        </a-col>

        <!-- Devices Select All Button -->
        <a-col :span="isAnalytics ? 6 : 3">
          <a-button block @click="handleSelectAll">
            {{ isAllDeviceSelected ? 'Unselect all' : 'Select all' }}
          </a-button>
        </a-col>

        <!-- Cycle Range Inputs & Close Button -->
        <a-col v-if="showDurationInput" span="9">
          <a-input-group compact>
            <a-input
              v-model:value="durationStartTime"
              addon-before="Cycle Range:"
              type="number"
              placeholder="Min Time (s)"
              style="width: 55%"
            />
            <a-input
              v-model:value="durationEndTime"
              type="number"
              placeholder="Max Time (s)"
              style="width: 45%"
            >
              <template #addonAfter>
                <close-circle-outlined
                  v-if="durationStartTime || durationEndTime"
                  @click="durationStartTime = durationEndTime = null"
                />
                <clock-circle-outlined v-else />
              </template>
            </a-input>
          </a-input-group>
        </a-col>

        <!-- Steps Selector -->
        <a-col v-if="selectedTask && showStepsFilter" span="9">
          <a-select
            id="analytics-trace-select-option-filter"
            v-model:value="selectedSteps"
            mode="multiple"
            style="width: 100%"
            placeholder="Filter cycles with these missed steps"
            :filter-option="filterOption"
            :max-tag-count="2"
            :options="stepFilterOptions"
            :getPopupContainer="getPopupContainer"
          />
        </a-col>

        <!-- Step Select All Button -->
        <a-col v-if="selectedTask && showStepsFilter" span="3">
          <a-button block @click="handleSelectAllSteps">
            {{ isAllStepsSelected ? 'Unselect all' : 'Select all' }}
          </a-button>
        </a-col>

        <!-- Update Button -->
        <a-col :span="isAnalytics ? 6 : 3">
          <a-button
            id="analytics-trace-update-btn"
            type="primary"
            block
            :loading="isFetchingCycles"
            @click="getData()"
          >
            Update
          </a-button>
        </a-col>
      </a-row>
    </a-col>

    <a-col span="12">
      <a-row :gutter="[8, 16]">
        <!-- Date Range Picker For Comparison -->
        <a-col v-if="showCompareAnalytics" span="12">
          <a-range-picker
            class="w-100"
            id="analytics-trace-date-range-filter"
            :value="dateRangeForComparison"
            show-time
            :placeholder="['Start date (yyyy-mm-dd)', 'End date (yyyy-mm-dd)']"
            :getPopupContainer="getPopupContainer"
            @change="setDateRangeForComparison"
          />
        </a-col>

        <!-- Compare/Close Button-->
        <a-col
          :span="showCompareAnalytics ? 8 : 6"
          :offset="showCompareAnalytics ? 4 : 18"
          align="end"
        >
          <a-button
            v-if="!isNoDataAvailable && isAnalytics"
            block
            @click="setShowCompareAnalytics(!showCompareAnalytics)"
          >
            {{ showCompareAnalytics ? 'Close Comparison' : 'Compare' }}
            <template #icon>
              <CloseCircleOutlined v-if="showCompareAnalytics" />
              <DiffOutlined v-else />
            </template>
          </a-button>
        </a-col>

        <!-- Devices Selector For Comparison -->
        <a-col v-if="showCompareAnalytics" span="12">
          <a-select
            id="analytics-trace-select-device-filter-for-comparison"
            v-model:value="selectedDevicesForComparison"
            mode="multiple"
            class="w-100"
            placeholder="Select Device(s) For Comparison"
            :options="compareDeviceOptions"
            :loading="isFetchingDevices"
            :filter-option="filterOption"
            :getPopupContainer="getPopupContainer"
            :max-tag-count="1"
          />
        </a-col>

        <!-- Devices Select All Button For Comparison-->
        <a-col v-if="showCompareAnalytics" span="6">
          <a-button block @click="handleSelectAllComparisonDevices">
            {{ isAllCompareDeviceSelected ? 'Unselect all' : 'Select all' }}
          </a-button>
        </a-col>

        <!-- Compare & Close Buttons for Comparison -->
        <a-col v-if="showCompareAnalytics" class="text-end d-flex" span="6">
          <a-button
            type="primary"
            block
            :loading="isFetchingAnalyticsForComparison"
            @click="getDataForComparison"
          >
            Compare
          </a-button>
        </a-col>
      </a-row>
    </a-col>
  </a-row>
</template>
<script>
import {
  ClockCircleOutlined,
  CloseCircleOutlined,
  DiffOutlined,
} from '@ant-design/icons-vue';
import dayjs from 'dayjs';
import { chartsIndicesForSecondDateRange } from 'src/config/charts-config';
import { dateTimeFormat } from 'src/config/date-format-config.js';
import spaceMixin from 'src/mixins/handleSpace';
import { getSortedDevice } from 'src/utils/device';
import { getSortedTask } from 'src/utils/task';
import { mapActions, mapGetters } from 'vuex';
import dateHelper from '../Helpers/dateHelper';
import getTime from '../Helpers/getTime';
import populateFilters from '../Helpers/populateFilters';
import updateUrl from '../Helpers/updateUrl';

export default {
  components: { CloseCircleOutlined, ClockCircleOutlined, DiffOutlined },
  mixins: [spaceMixin],
  inject: ['toast'],
  props: {
    showCycleInput: { type: Boolean, required: true, default: false },
    showDurationInput: { type: Boolean, required: true, default: false },
    showStepsFilter: { type: Boolean, required: true, default: false },
    isNoDataAvailable: { type: Boolean, required: true, default: false },
    isAnalytics: { type: Boolean, required: true, default: false },
  },
  emits: ['change', 'updateCards', 'searchDocs', 'changePage'],
  data() {
    return {
      dateRange: [dateHelper._getDate(), dateHelper._getDate()],
      selectedDevices: [],
      selectedDevicesForComparison: [],
      selectedSteps: [],
      minCycleTime: 0,
      maxCycleTime: 0,
      cyclesFromParams: null,
      durationStartTime: null,
      durationEndTime: null,
    };
  },

  computed: {
    ...mapGetters([
      'isFetchingCycles',
      'organization',
      'isFetchingTasks',
      'allTasks',
      'selectedTask',
      'isFetchingDevices',
      'isFetchingCycles',
      'devices',
      'cycleTypes',
      'selectedCycleType',
      'deviceSerialNumToDisplayNameMap',
      'deviceDisplayNameToSerialNumMap',
      'negativeSteps',
      'optionalSteps',
      'taskProcesses',
      'stepsToIndexMapping',
      'virtualTasks',
      'selectedVirtualTask',
      'showCompareAnalytics',
      'dateRangeForComparison',
      'isFetchingAnalyticsForComparison',
    ]),

    taskOptions() {
      return getSortedTask(this.allTasks);
    },

    deviceOptions() {
      const filteredDevices = this.devices?.filter(
        (device) =>
          !this.selectedDevices.includes(
            device.display_name || device.Serial_number
          ) && !!device.Serial_number
      );
      return getSortedDevice(filteredDevices);
    },

    compareDeviceOptions() {
      const filteredDevices = this.devices?.filter(
        (device) =>
          !this.selectedDevicesForComparison.includes(
            device.display_name || device.Serial_number
          ) && !!device.Serial_number
      );
      return getSortedDevice(filteredDevices);
    },

    cycleTypeOptions() {
      return Object.entries(this.cycleTypes).map(([key, value]) => ({
        value: value,
        label: key,
      }));
    },

    isAllDeviceSelected() {
      return this.selectedDevices.length === this.devices.length;
    },

    isAllCompareDeviceSelected() {
      return this.selectedDevicesForComparison.length === this.devices.length;
    },

    isAllStepsSelected() {
      return (
        this.selectedSteps.length ===
        this.stepFilterOptions?.filter(
          (option) => !this.isNegativeOrOptionalStep(option.value)
        ).length
      );
    },

    stepFilterOptions() {
      if (!this.taskProcesses.length) return;
      let subSteps = [];
      this.taskProcesses.forEach((process) => {
        const processSubSteps = process.steps.reduce(
          (res, el) => [...res, ...el.substeps],
          []
        );
        subSteps = [...subSteps, ...processSubSteps];
      });
      return subSteps.map((step) => {
        let option = {
          value: this.stepsToIndexMapping[step],
          label: step,
        };
        option['disabled'] = this.isNegativeOrOptionalStep(option.value);
        if (this.negativeSteps.includes(option.value)) {
          option['label'] = option['label'] + '  -  ' + '(negative)';
          option['class'] = 'text-secondary';
        }
        if (this.optionalSteps.includes(option.value)) {
          option['label'] = option['label'] + '  -  ' + '(optional)';
          option['class'] = 'text-secondary';
        }
        return option;
      });
    },
  },

  watch: {
    cyclesFromParams(value) {
      if (!value || !Array.isArray(value)) return;
      const data = this.getCardsData();
      this.$emit('updateCards', data);
    },

    selectedTask(value) {
      if (!value) return;
      this.fetchTaskDetails(value);
      this.fetchVirtualTasks(value);
    },

    selectedVirtualTask(value) {
      if (value) {
        this.fetchVirtualTaskDetails(value);
      } else {
        this.resetVirtualTaskStates();
      }
    },

    async devices() {
      await populateFilters.apply(this);
    },

    taskProcesses() {
      this.setIndexToStepsMapping();
      this.setStepsToIndexMapping();
      this.setStepToAttributesMapping();
    },

    showCompareAnalytics(value) {
      if (!value) {
        const newQueryParam = { ...this.$route.query };
        delete newQueryParam['c_start_date'];
        delete newQueryParam['c_end_date'];
        delete newQueryParam['c_devices'];
        this.selectedDevicesForComparison = [];
        this.setDateRangeForComparison([
          dateHelper._getDate(),
          dateHelper._getDate(),
        ]);
        this.$router.replace({
          name: this.$route.name,
          query: newQueryParam,
        });
      }
    },
  },

  created() {
    this.getAllTasks();
    this.getOrgDevices(this.organization);
    if (this.selectedTask) {
      this.setIndexToStepsMapping();
      this.setStepsToIndexMapping();
    }
    this.emitter.on('getAnalyticsAndTrace', this.getData);
  },

  methods: {
    ...mapActions([
      'getAllTasks',
      'fetchTaskDetails',
      'getOrgDevices',
      'setSelectedTask',
      'setSelectedCycleType',
      'setIndexToStepsMapping',
      'setStepsToIndexMapping',
      'setStepToAttributesMapping',
      'fetchVirtualTasks',
      'fetchVirtualTaskDetails',
      'resetVirtualTaskStates',
      'setSelectedVirtualTask',
      'setShowCompareAnalytics',
      'setDateRangeForComparison',
    ]),

    disabledStartDate(current) {
      return (
        current &&
        this.getDateTime(current) > this.getDateTime(this.dateRange[1])
      );
    },

    disabledEndDate(current) {
      return (
        current &&
        this.getDateTime(current) < this.getDateTime(this.dateRange[0])
      );
    },

    getPopupContainer(trigger) {
      return trigger.parentNode;
    },

    handleTaskSelect(val) {
      if (val !== this.selectedTask) this.selectedSteps = [];
      this.setSelectedTask(val);
      this.$refs.analytics_trace_task_select.blur();
    },

    getVideoS3Details(video, isPredictionFile = false) {
      const { fileName, device_id } = video;
      const bucket = `${this.organization}-videos`;
      let filePath = `${device_id}/${this.selectedTask}/Processed/${fileName}`;
      if (isPredictionFile) filePath += '_preds.json';
      return { bucket, filePath };
    },

    checkDuration() {
      let notValid = false;
      if (this.durationEndTime && this.durationStartTime) {
        notValid =
          parseInt(this.durationEndTime) <= parseInt(this.durationStartTime)
            ? true
            : false;
      }
      return notValid;
    },

    getPayload() {
      const data = {
        organization: this.organization,
        task: this.selectedTask,
        devices: this.getSelectedDevicesForURL(),
        datetime_start: this.getDateTime(this.dateRange[0]),
        datetime_end: this.getDateTime(this.dateRange[1]),
        cyclesFromParams: this.cyclesFromParams,
        cycle_type: this.selectedCycleType,
      };

      if (!this.selectedTask) {
        this.toast.info('Task is not selected');
        return false;
      }

      if (
        this.getDateTime(this.dateRange[0]) >
        this.getDateTime(this.dateRange[1])
      ) {
        this.toast.info('End date should not be before start date.');
        return false;
      }

      if (this.selectedDevices.length === 0) {
        this.toast.info('Device is not selected');
        return false;
      }

      if (this.durationStartTime && !this.durationEndTime) {
        this.toast.info('Duration must have both start and end time');
        return false;
      }

      if (this.checkDuration()) {
        this.toast.info(
          'In Duration, End time should be greater than start time!'
        );
        return false;
      }

      if (this.durationStartTime) data['start_time'] = this.durationStartTime;
      if (this.durationEndTime) data['end_time'] = this.durationEndTime;
      if (this.minCycleTime) data['min_cycle_time_in_bin'] = this.minCycleTime;
      if (this.maxCycleTime) data['max_cycle_time_in_bin'] = this.maxCycleTime;
      if (this.selectedVirtualTask)
        data['virtualTask'] = this.selectedVirtualTask;
      if (this.selectedSteps.length)
        data['missed_steps'] = this.getSelectedStepData();
      if (this.showCompareAnalytics) {
        this.setStartEndDateForComparison(data);
        if (this.selectedDevicesForComparison) {
          data['c_devices'] = this.getSelectedCompareDevicesForURL();
        }
      }

      return data;
    },

    setStartEndDateForComparison(data) {
      const [startDate, endDate] = this.dateRangeForComparison;
      const [_startDate, _endDate] = [
        this.getDateTime(startDate),
        this.getDateTime(endDate),
      ];
      data['c_start_date'] = _startDate;
      data['c_end_date'] = _endDate;
    },

    getData(isTraceOnly = false, callBack = () => {}) {
      const data = this.getPayload();
      !this.isKaizenAdvisor && updateUrl.apply(this, [data]);
      data['task'] = this.selectedVirtualTask || this.selectedTask;

      if (!isTraceOnly) this.$emit('changePage', 1);
      this.$emit('change', data, isTraceOnly, callBack);
    },

    getDataForComparison() {
      const data = this.getPayload();
      this.setStartEndDateForComparison(data);
      !this.isKaizenAdvisor && updateUrl.apply(this, [data]);
      data['task'] = this.selectedVirtualTask || this.selectedTask;
      data['datetime_start'] = data['c_start_date'];
      data['datetime_end'] = data['c_end_date'];
      data['devices'] = data['c_devices'];
      data['isComparing'] = true;
      this.$emit('change', data);
      return data;
    },

    getDateTime(date) {
      return `${dayjs(date).format(dateTimeFormat)}`;
    },

    redirectToPage(name, query = {}, chartIndex) {
      let key = 'dateRange';
      if (this.showCompareAnalytics) {
        key = chartsIndicesForSecondDateRange.includes(chartIndex)
          ? 'dateRangeForComparison'
          : key;
      }
      const [startDate, endDate] = this[key];

      query = {
        ...query,
        taskId: this.selectedTask,
        deviceId: this.getSelectedDevicesForURL(),
        startTime: this.getDateTime(startDate),
        endTime: this.getDateTime(endDate),
      };

      let { href } = this.$router.resolve({ name, query });
      window.open(href, '_blank');
    },

    getSelectedDevicesForURL() {
      return JSON.stringify(
        this.selectedDevices.map((d) => this.deviceDisplayNameToSerialNumMap[d])
      );
    },
    getSelectedCompareDevicesForURL() {
      return JSON.stringify(
        this.selectedDevicesForComparison.map(
          (d) => this.deviceDisplayNameToSerialNumMap[d]
        )
      );
    },

    getSumOfCycles() {
      return this.cyclesFromParams.reduce((res, el) => res + el);
    },

    getAverageCycleTime(sumOfCyclesTime, cyclesCount) {
      const averageCycleTime = sumOfCyclesTime / cyclesCount;
      return getTime(averageCycleTime * 1000);
    },

    getSelectedStepData() {
      const paramsSteps = this.selectedSteps.map((ix) => ix);
      return JSON.stringify(paramsSteps);
    },

    isNegativeOrOptionalStep(index) {
      return (
        this.negativeSteps?.includes(index) ||
        this.optionalSteps?.includes(index)
      );
    },

    getCardsData() {
      const sum_of_cycles_time = this.getSumOfCycles();
      const number_of_cycles = this.cyclesFromParams.length;
      const total_cycles_time = getTime(sum_of_cycles_time * 1000);
      const average_cycle_time = this.getAverageCycleTime(
        sum_of_cycles_time,
        number_of_cycles
      );

      return {
        number_of_cycles,
        average_cycle_time,
        total_cycles_time,
        total_inference_time: total_cycles_time,
      };
    },

    handleSelectAll() {
      if (this.isAllDeviceSelected) {
        this.selectedDevices = [];
        return;
      }
      this.selectedDevices = this.devices.map(
        (d) => d.display_name || d.Serial_number
      );
    },
    handleSelectAllComparisonDevices() {
      if (this.isAllCompareDeviceSelected) {
        this.selectedDevicesForComparison = [];
        return;
      }
      this.selectedDevicesForComparison = this.devices.map(
        (d) => d.display_name || d.Serial_number
      );
    },

    handleSelectAllSteps() {
      if (this.isAllStepsSelected) {
        this.selectedSteps = [];
        return;
      }
      this.selectedSteps = this.stepFilterOptions
        ?.map((step) => step.value)
        .filter((index) => !this.isNegativeOrOptionalStep(index));
    },

    handleVirtualTaskChange(e) {
      const { value } = e.target;
      this.setSelectedVirtualTask(value);
    },
  },
};
</script>
<style></style>
