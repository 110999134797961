import ApiHelper from './index';

const getTraceVideos = async (spinner = true, queryParams) => {
  return await ApiHelper.get('telemetry/trace/', spinner, queryParams);
};

const getTraceVideosV2 = async (spinner = true, queryParams) => {
  return await ApiHelper.get('telemetry/trace/v2', spinner, queryParams);
};

const fetchCyclesDetails = async (payload, spinner = true) => {
  return await ApiHelper.post('telemetry/fetch_cycles/', payload, spinner);
};

const fetchCyclesDetailsV2 = async (spinner = true, queryParams) => {
  return await ApiHelper.get('telemetry/fetch_cycles/v2', spinner, queryParams);
};

const fetchTopFivePercentile = async (spinner = true, queryParams) => {
  return await ApiHelper.get(
    'telemetry/top_five_percentile',
    spinner,
    queryParams
  );
};

const getTraceCycleRecordData = async (queryParams, spinner = false) => {
  return await ApiHelper.get(
    'telemetry/trace/trace_single_cycle_details/',
    spinner,
    queryParams
  );
};

const archiveCycle = async (cycleId) => {
  return await ApiHelper.remove(`/telemetry/cycle/${cycleId}/`);
};

export default {
  getTraceVideos,
  fetchCyclesDetails,
  getTraceVideosV2,
  fetchCyclesDetailsV2,
  fetchTopFivePercentile,
  archiveCycle,
  getTraceCycleRecordData,
};
